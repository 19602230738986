<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" />
      <section class="section">
        <div class="row">
          <div v-for="item in dataBoard" :key="item.index" class="col">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay>
                  <template v-slot:title>{{ item.title }}</template>
                  <template v-slot:num>{{ item.number }}</template>
                  <template v-slot:sup>{{ item.subTitle }}</template>
                  <template v-if="item.subNumber" v-slot:sub>
                    <span class="numDisplay-sub-sup">{{ item.subLabel }}</span>
                    {{ item.subNumber }}
                  </template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col-6">
            <Panel type="board">
              <template v-slot:headline>男女比率</template>
              <template v-slot:body>
                <ChartDoughnut class="chart-pie" :chartData="chartGender" :options="chartOptions" />
              </template>
            </Panel>
          </div>
          <div class="col-6">
            <Panel type="board">
              <template v-slot:headline>年代別比率</template>
              <template v-slot:body>
                <ChartDoughnut class="chart-pie" :chartData="chartAge" :options="chartOptions" />
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:headline>イベント一覧</template>
              <template v-slot:body>
                <div class="scrollX">
                  <Table :dataTable="dataTable" :labels="labels"></Table>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <Notification v-if="isGmoOrOfficeLoginStoreAndStoreGroup"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//helpers
import { formatLocalString, formatBetweenTwoDates } from '@/helpers/formatData';
//mixins
import status from '@/mixins/plugin/status';
import event from '@/mixins/module/event';
import reloadOnPage from '@/mixins/module/reloadOnPage';
//components
import NumDisplay from '@/components/NumDisplay.vue';
import Table from '@/components/Table.vue';
import ChartDoughnut from '@/components/ChartDoughnut.vue';
import Notification from '@/components/Notification.vue';

const popStateListener = () => {
  history.go(1);
}

export default {
  name: 'EventDashboard',
  data: function () {
    return {
      chartOptions: {
        maintainAspectRatio: false,
        legend: {
          position: 'right',
          labels: {
            boxWidth: 12,
            fontColor: 'rgb(79, 78, 105)',
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      parentEventDetail: 'event/parentEventDetail',
      childEventList: 'event/childEventList',
      parentEventAggregate: 'aggregate/parentEventAggregate',
      newSubdomain: 'common/subdomain',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
      isUsePointFlagParentEvent: 'event/isUsePointFlagParentEvent',
      canUseCpmParentEvent: 'event/canUseCpmParentEvent',
      isOnlyChildEvent: 'event/isOnlyChildEvent',
      isClientChild: 'auth/isClientChild',
      viewAllhistory: 'event/viewAllhistory',
    }),
    pageName() {
      return this.parentEventDetail?.name || '親イベント名';
    },
    subdomain() {
      if (this.$permission.isStoreOrGroup()) {
        return this.infor.event?.subdomain || null;
      } else {
        return (this.isGmoOrOfficeLoginStore ? this.newSubdomain : this.$route.params?.subdomain) || null;
      }
    },
    customerDelectedAccount() {
      return this.parentEventAggregate.deleted
    },
    customerActiveAccount() {
      return this.parentEventAggregate.active
    },
    customerSumAccount() {
      return this.customerDelectedAccount + this.customerActiveAccount;
    },
    customerDelectedAccountRate() {
      if (this.customerSumAccount <= 0)
        return 0;
      return (this.customerDelectedAccount / this.customerSumAccount * 100).toFixed(1);
    },
    labels() {
      const clientMasterCondition = !this.$permission.isStoreOrGroup() && !this.isClientChild;
      const clientChildCondition = this.isClientChild && this.isOnlyChildEvent;
      const hasChargeAggregate = this.$permission.isStoreOrGroup() || (this.isClientChild && !this.viewAllhistory);
      return {
        name: 'イベント名',
        clients: '事務局名／企業名',
        date: '使用可能期間',
        status: 'ステータス',
        shopAggregate: '店舗数',
        ...(!hasChargeAggregate && { chargeAggregate: '累計発行金額' }),
        ...((this.isUsePointFlagParentEvent && this.canUseCpmParentEvent && !this.isGmoOrOfficeLoginStore && (clientMasterCondition || clientChildCondition)) && { amountPoint: '累計ポイント発行額' })
      }
    },
    dataBoard() {
      return [
        {
          title: '店舗アクティブアカウント数',
          number: formatLocalString(this.parentEventAggregate.shops?.active),
          subTitle: '件',
          subLabel: '登録累計数',
          subNumber: formatLocalString(this.parentEventAggregate.shops?.total) + '件',
        },
        {
          title: 'アカウント登録数',
          number: formatLocalString(this.customerActiveAccount),
          subTitle: '人',
          subLabel: false,
          subNumber: false,
        },
        {
          title: '退会数',
          number: formatLocalString(this.customerDelectedAccount),
          subTitle: '人/' + formatLocalString(this.customerSumAccount) + '人',
          subLabel: '退会率',
          subNumber: this.customerDelectedAccountRate + '%',
        },
      ]
    },
    //chartData
    chartGender() {
      return {
        labels: ['男性', '女性', 'その他', '未選択'],
        datasets: [
          {
            data: Object.values(this.parentEventAggregate.gender),
            backgroundColor: ['rgb(94, 129, 244)', 'rgb(255, 128, 139)', 'rgb(238, 198, 56)', 'rgb(178, 183, 210)'],
            hoverBackgroundColor: ['rgb(94, 129, 244)', 'rgb(255, 128, 139)', 'rgb(238, 198, 56)', 'rgb(178, 183, 210)'],
            borderColor: 'rgba(0, 0, 0, 0)',
            borderWidth: 1,
          },
        ],
      }
    },
    chartAge() {
      return {
        labels: ['10代まで', '20代', '30代', '40代', '50代', '60代', '70代以上', '未選択'],
        datasets: [
          {
            data: Object.values(this.parentEventAggregate.age),
            backgroundColor:  ['rgb(201, 233, 102)', 'rgb(255, 128, 139)', 'rgb(239, 199, 32)', 'rgb(65, 219, 172)', 'rgb(94, 129, 244)', 'rgb(147, 102, 219)', 'rgb(79, 78, 105)', 'rgb(178, 183, 210)'],
            hoverBackgroundColor:  ['rgb(201, 233, 102)', 'rgb(255, 128, 139)', 'rgb(239, 199, 32)', 'rgb(65, 219, 172)', 'rgb(94, 129, 244)', 'rgb(147, 102, 219)', 'rgb(79, 78, 105)', 'rgb(178, 183, 210)'],
            borderColor: 'rgba(0, 0, 0, 0)',
            borderWidth: 1,
          },
        ],
      }
    },
    dataTable() {
      return this.childEventList.map((item) => {
        return {
          ...item,
          name: this.childNameContent(item),
          clients: this.clientContent(item.clients),
          status: {
            content: item.status?.name,
            class: this.statusClass(item.status?.name),
          },
          date: formatBetweenTwoDates(item.usablePeriod.start, item.usablePeriod.end),
          shopAggregate: formatLocalString(item.aggregate?.shops),
          chargeAggregate: '¥' + formatLocalString(item.aggregate?.charge),
          amountPoint: (item.aggregate?.amountPoint === '-') ? item.aggregate?.amountPoint : (formatLocalString(item.aggregate?.amountPoint) + 'P'),
        };
      });
    },
  },
  components: {
    NumDisplay,
    ChartDoughnut,
    Table,
    Notification,
  },
  mounted() {
    if (this.$route.query?.back) {
      history.pushState(null, document.title, location.href);
      window.addEventListener('popstate', popStateListener);
    }
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('aggregate/getParentEventAggregate', { subdomain: this.subdomain }),
      this.$store.dispatch('event/getChildEventList', {
        subdomain: this.subdomain,
        hasAggregate: 1,
        includeUnregistered: 1,
        limit: 20,
      }),
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  },
  beforeDestroy() {
    window.removeEventListener('popstate', popStateListener);
  },
  mixins: [status, event, reloadOnPage],
};
</script>
