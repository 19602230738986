export default {
  mounted() {
    window.addEventListener("pageshow", this.handlePageShow);
  },
  methods: {
    handlePageShow(event) {
      var historyTraversal = event.persisted || ( typeof window.performance != "undefined" && window.performance.navigation.type === 2 );
      if ( historyTraversal ) {
        // Handle page restore.
        window.location.reload();
      }
    },
  },
};
